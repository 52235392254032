import React from 'react';
import { Row, Col, Card } from 'antd';



const EduImages = ({images}) => {
  return (
    <div style={{ padding: '30px' }}>
      <Row gutter={[16, 16]}>
        {images.map((src, index) => (
          <Col span={12} key={index}>
           <img alt={`example-${index}`} src={src} style={{ margin:"0",width: '100%',height:"250px", borderRadius:"10px" }} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default EduImages;
