import React from 'react';
import { Form, Input, Select, Button, Row, Col,message } from 'antd';
import axios from 'axios';
const { Option } = Select;

const services = [
  "Data Entry",
  "Data Management and Processing",
  "Email Management",
  "Virtual Assistant",
  "Book keeping",
  "Payroll",
  "Accounting"
];

const SForm = () => {
  const [form] = Form.useForm();

  

  const handleSubmit = async (values:any) => {
    values.form_type= "service";
    try {
      const response = await axios.post('http://levelup2o-dev.exavale.com/send.php', values);
      if (response.data.status) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error('An error occurred while sending the email.');
    }
  };


  return (

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
         
          initialValues={{
            size: "large",
          }}
          
          size="large"
         
        >
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[{ required: true, message: 'Please enter your company name' }]}
            labelCol={{ style: { color: '#fff' } }}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter your name' }]}
            labelCol={{ style: { color: '#fff' } }}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[{ required: true, message: 'Please enter your designation' }]}
            labelCol={{ style: { color: '#fff' } }}
          >
            <Input placeholder="Designation" />
          </Form.Item>
          <Form.Item
            name="emailId"
            label="Email Id"
            rules={[{ required: true, type: 'email', message: 'Please enter a valid email id' }]}
            labelCol={{ style: { color: '#fff' } }}
          >
            <Input placeholder="Email Id" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone No."
            rules={[{ required: true, message: 'Please enter your phone number' }]}
            labelCol={{ style: { color: '#fff' } }}
          >
            <Input placeholder="Ph No" />
          </Form.Item>
          <Form.Item
            name="services"
            label="What Services are you interested in"
            rules={[{ required: true, message: 'Please select at least one service' }]}
            labelCol={{ style: { color: '#fff' } }}
          >
            <Select
              mode="multiple"
              placeholder="Select services"
              allowClear
            >
              {services.map(service => (
                <Option key={service} value={service}>
                  {service}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div style={{textAlign:"center"}}> 
            <Button    className='button text-center' type="primary" htmlType="submit">
              Submit
            </Button>
            </div>
          </Form.Item>
        </Form>
     
  );
};

export default SForm;
