

  import styled from "styled-components";


export const HdContent = styled.section`
  
 

  
`

