import React, { createContext, useState, useContext } from 'react';

// Define the context type
interface AppContextType {
  value: string;
  handleClick: (newValue: string) => void;
}

// Create a Context with a default value
const AppContext = createContext<AppContextType | undefined>(undefined);

// Create a custom hook to use the AppContext
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

// Create a Provider component
export const AppProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState('');

  const handleClick = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <AppContext.Provider value={{ value, handleClick }}>
      {children}
    </AppContext.Provider>
  );
};
