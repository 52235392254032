import React, { useState } from 'react';
import { Container } from "./styles";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message
} from 'antd';
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;

const AForm = () => {
  const [form] = Form.useForm();

  const onFormLayoutChange = (values: any) => {
    console.log("Enquiry Form Values", values);
  };

  const handleSubmit = async (values:any) => {
    values.form_type= "contact";
    try {
      const response = await axios.post('http://levelup2o-dev.exavale.com/send.php', values);
      if (response.data.status) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error('An error occurred while sending the email.');
    }
  };

  // const handleSubmit = async (values: any) => {
  //   try {
  //     const response = await axios.post('http://levelup2o-dev.exavale.com/send.php', values, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     const data = response.data;
  //     console.log(data);
  //   } catch (error) {
  //    console.log(error);
  //   }
  // };

  
  // const handleSubmit = async (values: any) => {
  //   try {
  //     const response = await axios.post('http://levelup2o-dev.exavale.com/send.php', values);
  //     if (response.status === 200) {
  //       console.log('Form submitted successfully');
  //     }
  //   } catch (error) {
  //     console.error('Form submission failed', error);
  //   }
  // };

  return (
  
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            size: "large",
          }}
          onFinish={handleSubmit}
          size="large"
        >



          <Form.Item
            name="type"
            label="Are you a student or a guardian?"
            rules={[{ required: true, message: 'Please select an option' }]}
          >
            <Select placeholder="Select one">
              <Option value="student">Student</Option>
              <Option value="guardian">Guardian</Option>
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: 'Please enter your first name' }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: 'Please enter your last name' }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="contactNo"
            label="Contact No"
            rules={[{ required: true, message: 'Please enter your contact number' }]}
          >
            <Input placeholder="Contact No" />
          </Form.Item>
          <Form.Item
            name="emailId"
            label="Email Id"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'The input is not a valid email' }
            ]}
          >
            <Input placeholder="Email Id" />
          </Form.Item>
          <Form.Item
            name="enquiryDescription"
            label="Enquiry (Description)"
            rules={[{ required: true, message: 'Please enter your enquiry description' }]}
          >
            <TextArea placeholder="Enquiry Description" />
          </Form.Item>
          
          <Form.Item style={{ textAlign: "center" }}>
            <Button className="button" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
     
  );
};

export default AForm;
