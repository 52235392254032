import { BrowserRouter } from "react-router-dom"
import { Container,StyleCss } from "./styles";

import BannerImage from "../../assets/Banner.png";
import wordpress from "../../assets/wordpress.svg";
import shopify from "../../assets/shopify.svg";
import htmlIcon from "../../assets/html-icon.svg";
import cssIcon from "../../assets/css-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import vueIcon from "../../assets/vue-icon.svg";
import boostrapIcon from "../../assets/bootstrap-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";
import { NavHashLink } from "react-router-hash-link"
import EduImages from "../EduImages/EduImages";
// import AForm from "../AForm/AForm";
import DesSlider from "../DesSlider/DesSlider";

export function About() {
  return (
    <>
    <StyleCss >
    <div className="about-text ">
    <ScrollAnimation animateIn="fadeInLeft">
          <h1 style={{fontSize:"45px" ,color:"var(--blue)"}}> About Us</h1>
        </ScrollAnimation>

        <br/>
        <ScrollAnimation animateIn="fadeInRight" delay={0.1 * 1000}>
          <p>
          Level UP 2.0 was incorporated with a vision to provide professional services to its clients/ patrons. We as an organization offer education consultancy to students aspiring to study overseas. 
Additionally, we offer a range of business support services for organisations. 
          </p>
        </ScrollAnimation>
     
        </div>

    </StyleCss>

    <Container id="education">
      <div className="about-text">
        
        <div className="insideText">
        <h2 > Education Consultancy  </h2>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          Our aim is to help students in their pursuit for higher education overseas. We have a team of experienced professionals, who bring with them years of experience and expertise. Based on the student profile, despite the education level, we help in drafting the education pathway for students keeping in view their career goals. 
          </p>
        </ScrollAnimation>
          
    
        <br/><br/>
        <h2 > Services Provided </h2>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginBottom: "1rem" }}>
          <p className="service-span">
          Counselling and Guidance for Studies Overseas. <br/>

            <span>
            - Counselling & Profile Evaluation.<br/>
            - Profile based course selection.<br/>
            - Application and Admission Assistance.<br/>
            - Pre-Departure Briefing.
            </span>

          </p>
        </ScrollAnimation>
        <br/><br/>

      
      </div>
      
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight"   delay={0.20 * 1000}>
        <EduImages images={[
  './../services/education-1.png',
  './../services/education-2.png',
  './../services/education-3.png',
  './../services/education-4.png'
]} />
        </ScrollAnimation>



      </div>

      
    </Container>
<StyleCss >
    <div className="about-text ">

      
      <div className="">

     <div className="study_slider">
     <h2 > Study Destinations</h2>
     
          <DesSlider sliderType={"education"} />

     </div>
     <br/><br/>
    {/* <h2 > - Singapore </h2>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginBottom: "2rem" }}>
          <p>
         

          <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginBottom: "2rem" }}>
          <p>
          
          Singapore has a reputation for academic excellence not just in Asia, but across the world. The nation’s education system is considered world-class and boasts academic talent that is both highly advanced and competitive. <br/><br/>

          <abbr title="Quick facts:">Quick facts:</abbr><br/>
Ranked as the second-best country to live and work in, according to an HSBC ranking in 2019
Courses are more affordable than in the US or the UK
One of the financial capitals of the world
A crucial benefit of choosing Singapore is its distance from India. Singapore is closer to home. Indians comprise nearly 10% of the country's population. So you will feel like you're close to home.
          </p>
        </ScrollAnimation>
        <br/>
        <h2 > - Malaysia </h2>
  
          <p> 
          With natural attractions, rich culture and gorgeous scenery, Malaysia attracts people from all over the world. The country has a unique combination of ancient and modern architecture, inspired by their mix of cultures and ethnicities. Its location makes it the perfect destination for any international student looking for a new experience for their studies and has progressively emerged as one of the prominent student destinations in Asia. Home to many international Universities, it is well-known for providing quality education at inexpensive costs as well as simpler visa and immigration procedures thus attracting students from across the globe.<br/><br/>

          <abbr title="Quick facts:">Quick facts:</abbr><br/>
Malaysia is an increasingly popular place for higher education with international students from over 100 countries studying in Malaysia
Affordable cost of living and cost of education
Home to many foreign Universities
After completion of higher education, employment prospects widen.
          </p>
          <br/>
          <h2 > - Vietnam </h2>

          <p>
        Vietnam's growing economy and increasing global integration offer promising opportunities for international students after graduation. Many multinational companies operate in Vietnam, providing job prospects in various sectors such as technology, finance, hospitality, and manufacturing
          </p>
          </p>
        </ScrollAnimation> */}

        
       </div>

    </div>
    </StyleCss>


    <Container id="business">
      <div className="about-text">
        
        <div className="insideText">
        <h2 > Business Support Services   </h2>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          We offer a broad range of business support services along with remote hiring for organizations. We firmly believe that companies of any size can enjoy the benefits of a support service operating model. For small businesses, it reduces overheads and increases revenue for growth. Large businesses benefit from outsourcing during significant change or rapid growth. Whether you seek a remote team of one or a hundred, we stand ready with solutions tailored to your precise requirements and ambitions
          </p>
        </ScrollAnimation>
          
    
        <br/><br/>
        <h2 >Core Values </h2>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginBottom: "1rem" }}>
          <p className="bservice-span">
       

            <span>
            - Integrity<br/>
            - Commitment<br/>
            - Professional Approach<br/>
            </span>

          </p>
        </ScrollAnimation>
        <br/><br/>

      
      </div>
      
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight"   delay={0.20 * 1000}>
        <EduImages images={[
  './../services/service-1.png',
  './../services/service-2.png',
  './../services/service-3.png',
  './../services/service-4.png'
]} />
        </ScrollAnimation>



      </div>

      
    </Container>

    <StyleCss >
    <div className="study_slider">
     <h2 >  Services</h2>
     
          <DesSlider sliderType="business" />

     </div>
     </StyleCss>
    </>
  )
}
