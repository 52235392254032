import styled from "styled-components";

export const StyleCss = styled.section`
margin-top: 1px;
    

.study_slider {
  background: #151b543b;
  padding: 30px 25px;
  text-align: center;
  box-shadow: 0 0 3px #c9cad7;
  border-radius: 10px;



  .slick-slide  {

  img{
      display: block;
      border-radius: 10px;
      width: 100%;
      height: 250px;
 &:hover {
        cursor:pointer;
        border: 3px solid #ccc;
        background-color : #ccc;
        
      }
     
 }
    
}
  .slider-container {

  

    .slick-slide div {
      padding: 2px;
    }

    padding: 0 30px;

    .slick-prev {
      left: -48px;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 40px;
    }
  }

  .ant-ribbon .ant-ribbon-text {
    color: var(--blue);
    font-size: 25px;
  }

  .ant-ribbon.ant-ribbon-placement-start {
    padding: 0px 0;
    margin: 180px 8px;
    padding: 4px 5px !important;
  }

}

.hard-skills{
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.8rem;
}
.hability{
  display: flex;
  flex-direction: column;
  align-items: center;

  img{
    width: 3.4rem;
  }
}
.button{
  margin-top: 5rem;
  padding: 1.4rem 6rem;
}

h2{
  display: inline-block;
  margin-bottom: 2rem;
  // border-bottom: 0.2rem solid var(--blue);
  font-size :3rem;
  margin-top: 0rem;
  color: var(--green);

}
.insideText {
  position: relative;
  left: 5%;
  color: var(--text);
}

h3{
  margin-top: 2rem;
  color: var(--green);
}

p{
  font-size: 25px;
  letter-spacing: 0.1rem;
  font-weight: 500;
}



.about-image{
  text-align: center;
 img{
   margin-top: 2rem;
   width: 75%;
   filter: grayscale(0);
   transition: filter 0.5s;
   &:hover{
     filter: grayscale(0);
   }
 }
}

@media only screen and (max-width: 480px) {
  .about-image {
    max-width: 100%;
    margin-top: 4rem;
    img{
      margin-top: 2rem;
      width: 100%;
      filter: grayscale(0);
      transition: filter 0.5s;
      &:hover{
        filter: grayscale(0);
      }
  }
}

@media (max-width: 960px){
  display: block;
  text-align: center;
  
  .hard-skills{
    justify-content: center;
  }
  .about-image{
    display: flex;
    max-width: 100%;
    img{
      margin-top: 2rem;
      width: 100%;
      filter: grayscale(0);
      transition: filter 0.5s;
      &:hover{
        filter: grayscale(0);
      }
  }
  


.service-span {
  span{
    position: relative;
    left: 25px;
  }
}
  
}`;

export const Container = styled.section`
  margin-top: 8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  .hard-skills{
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.8rem;
  }
  .hability{
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      width: 3.4rem;
    }
  }
  .button{
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  h2{
    display: inline-block;
    margin-bottom: 2rem;
    // border-bottom: 0.2rem solid var(--blue);
    font-size :3rem;
    margin-top: 0rem;
    color: var(--green);
    
  }
  .insideText {
    position: relative;
    left: 5%;
    color: var(--text);
  }

  h3{
    margin-top: 2rem;
    color: var(--green);
  }

  p{
    font-size: 25px;
    letter-spacing: 0.1rem;
    font-weight: 500;
  }
  


  .about-image{
    text-align: center;
   img{
     margin-top: 2rem;
     width: 75%;
     filter: grayscale(0);
     transition: filter 0.5s;
     &:hover{
       filter: grayscale(0);
     }
   }
  }

  @media only screen and (max-width: 480px) {
    .about-image {
      max-width: 100%;
      margin-top: 4rem;
      img{
        margin-top: 2rem;
        width: 100%;
        filter: grayscale(0);
        transition: filter 0.5s;
        &:hover{
          filter: grayscale(0);
        }
    }
  }

  @media (max-width: 960px){
    display: block;
    text-align: center;
    
    .hard-skills{
      justify-content: center;
    }
    .about-image{
      display: flex;
      max-width: 100%;
      img{
        margin-top: 2rem;
        width: 100%;
        filter: grayscale(0);
        transition: filter 0.5s;
        &:hover{
          filter: grayscale(0);
        }
    }
    
    
  }

  

`