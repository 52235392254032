import styled from "styled-components";
export const Container = styled.section`
  padding-top: 2.5%;
 
  .slider_img {
    width: 100%;
    height: 450px;
    object-fit: contain;
  }

  button, .button {
    border: none;
    cursor: pointer;
    background-color: var(--green);
    color: #fff;
    border-radius: 2rem;
    font-weight: 500;
    -webkit-transition: filter 0.25s;
    transition: filter 0.25s;
  width: 50%;
    padding: 10px !important;
    font-size: 25px;
    font-weight: 600;
}
h1{
  font-size:62px;

  i{
    font-size:25px;
  }
}

.hero-text {
    button.slick-arrow {
        background: transparent !important;
    }
  .slick-prev:before, .slick-next:before {
    font-size:40px;
  }
}

`